import React from "react"
import { useStateIfMounted } from "use-state-if-mounted"
import { Container, Row, Col } from "react-bootstrap"
import AffiliateForm from "../Affiliate/AffiliateForm"
import {
  AffiliateFormHeader,
  SalutationAffiliate,
  AffiliateDot,
  AffiliateFormCol,
} from "./HeaderStyle"
import bgAffiliate from "../../images/bg-affiliate-header.jpg"
import bgAffiliateRes from "../../images/bg-affiliate-header-mobile.jpg"
import { NavigationBar } from "../NavigationBar/parent"
// import NavigationBar from "./NavigationBar"

function HeaderAffiliateForm({ props, propsLink, propsData }) {
  const [statusNav, setStatusNav] = useStateIfMounted(false)

  const statusNavbar = status => {
    setStatusNav(status)
  }

  const { Title, Desc } = propsData
  return (
    <AffiliateFormHeader style={{ paddingTop: 160 }}>
      <NavigationBar />
      <Container>
        <SalutationAffiliate>
          <h2>{Title}</h2>
        </SalutationAffiliate>
        <Row className="mt-4">
          <AffiliateFormCol md={8} lg={6}>
            <p>{Desc}</p>
            <AffiliateForm propsData={propsData} propsLink={propsLink} />
          </AffiliateFormCol>
        </Row>
      </Container>
    </AffiliateFormHeader>
  )
}

export default HeaderAffiliateForm
