import React from "react"
import { useLocation } from "@reach/router"
import HeaderAffiliateForm from "../components/HeaderComponents/HeaderAffiliateForm"
import Footer from "../components/FooterComponents/Footer"

import {
  MainPageJson,
  PathJson,
  AffiliateFormJson,
} from "../components/location"
import Seo from "../components/SEOComponents/Seo"

function AffiliateForm() {
  const MainPageContent = MainPageJson()
  const PathContent = PathJson()
  const AffiliateFormContent = AffiliateFormJson()
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")
  return (
    <div className="maxwidth">
      <Seo title="affiliate-form" />
      <HeaderAffiliateForm
        props={MainPageContent.Header}
        propsLink={PathContent}
        propsData={AffiliateFormContent}
      />
      <Footer props={MainPageContent.Footer} propsLink={PathContent} />
    </div>
  )
}

export default AffiliateForm
